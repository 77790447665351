
import { fetchBadges, fetchHasBadges } from "@/api/badge";
import { saveErrorLog } from "@/api/error";
import MBadgeCard from "@/components/badge/MBadgeCard.vue";
import MBadgeModal from "@/components/badge/MBadgeModal.vue";
import { Badge, HasBadge } from "@/entities/badge";
import store, { awaitStudentLoaded } from "@/store";
import { Options, Vue } from "vue-class-component";

type BadgeCardData = {
  badge: Badge;
  step: number;
};

@Options({
  components: {
    MBadgeCard,
    MBadgeModal
  }
})
export default class BadgeList extends Vue {
  badges: Badge[] = [];
  hasBadges: HasBadge[] = [];
  selectedBadgeId = "";

  get badgeCardDatas(): BadgeCardData[] {
    const datas = this.badges.map(badge => {
      const matchHasBadges = this.hasBadges.filter(
        item => item.data.badge.id === badge.ref.id
      );
      if (matchHasBadges.length === 0) {
        const data: BadgeCardData = {
          badge,
          step: -2
        };
        return data;
      } else {
        const data: BadgeCardData = {
          badge,
          step: matchHasBadges[0].data.stepIndex
        };
        return data;
      }
    });
    return datas;
  }

  get selectedBadgeData(): BadgeCardData | null {
    if (!this.selectedBadgeId) {
      return null;
    }
    const matchDatas = this.badgeCardDatas.filter(
      data => data.badge.ref.id === this.selectedBadgeId
    );
    if (matchDatas.length === 0) {
      return null;
    }
    return matchDatas[0];
  }

  setSelectedBadgeId(badgeId: string) {
    this.selectedBadgeId = badgeId;
  }

  async created() {
    store.commit("SET_LOADING", true);
    store.commit("SET_LOAD_TEXT", "バッジ取得中...");

    try {
      const student = await awaitStudentLoaded(store);
      const [badges, hasBadges] = await Promise.all([
        fetchBadges(),
        fetchHasBadges(student.ref)
      ]);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.badges = badges;
      this.hasBadges = hasBadges;
      if (this.$route.query.id) {
        this.selectedBadgeId = this.$route.query.id as string;
      }
    } catch (e) {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      alert(`バッジ情報の取得に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.student,
        e.code,
        e.message,
        "Failed to get badge datas"
      );
      return;
    }
  }
}

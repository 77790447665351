<template>
  <MBaseModal @close="close">
    <template #title>{{ badge.data.name }}</template>
    <template #body>
      <div class="flex flex-col items-center">
        <img :src="image" alt="バッジ画像" class="w-1/2" />
        <p class="my-6 text-sm text-gray-500 text-center">{{ description }}</p>
      </div>
      <div v-if="steps.length > 0" class="flex flex-wrap justify-center">
        <div
          v-for="step in steps"
          :key="step.label"
          class="w-16 mx-3 mb-4 flex flex-col items-center"
        >
          <img :src="step.image" alt="バッジ画像" />
          <p class="" :class="step.labelClass">{{ step.label }}</p>
        </div>
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import { Badge } from "@/entities/badge";

type BadgeStep = {
  label: string;
  labelClass: string;
  image: string;
};

@Options({
  components: {
    MBaseModal
  },
  emits: ["close"],
  props: {
    badge: Object,
    step: Number
  }
})
export default class MBadgeModal extends Vue {
  badge!: Badge;
  step = 0;

  get image(): string {
    if (this.step === -2) {
      return this.badge.data.blankImage;
    } else if (this.step === -1) {
      return this.badge.data.image;
    } else {
      return this.badge.data.stepsBadgeImage[this.step];
    }
  }

  get description(): string {
    if (this.step === -2) {
      return this.badge.data.placeholder;
    } else if (this.step === -1) {
      return this.badge.data.description;
    } else {
      return this.badge.data.stepsDescription[this.step];
    }
  }

  get steps(): BadgeStep[] {
    const steps: BadgeStep[] = [];
    for (const i in this.badge.data.steps) {
      const index = Number(i);
      let labelClass: string;
      let image: string;
      if (this.step >= index) {
        const color = this.badge.data.stepsBadgeColors[index];
        if (color === "gold") {
          labelClass = "text-primary-500";
        } else {
          labelClass = `text-${color}`;
        }
        image = this.badge.data.stepsBadgeImage[index];
      } else {
        labelClass = "text-gray-100";
        image = this.badge.data.blankImage;
      }
      steps.push({
        label: this.badge.data.stepsLabel[index],
        labelClass,
        image
      });
    }
    return steps;
  }

  close() {
    this.$emit("close");
  }
}
</script>
